<template>
  <div>
    <list-layout ref="recordLayout" :thead="thead" :filter-form="filterForm" :tab-arr="tabArr"
                 :on-fetch="getList" switch-route-when-tab-change="ApprovalList"
                 @command="handleOperation"
    >
      <template #filter>
        <approval-record-filter :filter-form="filterForm" :filter-change="onFilter" :export-data="{}"/>
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getRecordList } from "@/modules/approval/api";
import ApprovalRecordFilter from "@/modules/approval/components/index/Filter";

export default {
  components: {ApprovalRecordFilter, ListLayout},
  data() {
    return {
      filterForm: {
        "tab": "handling",  // handling：待处理、checked：已通过、rejected：已拒绝、cancel：已撤销、records：记录
        "page": 1,
        "page_size": 15,
        "order_by": "create_time", // create_time、finished_time
        "is_desc": 1,
        "code": "",
        "apply_user_name": "",
        "tmpl_name": "",
        "tmpl_id": "",
        "approval_user_name": "",
        "status": -1,
        "create_start_time": -1,
        "create_end_time": -1,
        "finished_start_time": -1,
        "finished_end_time": -1
      },
      thead: [
        {prop: 'code', label: '审批编号', width: 140},
        {prop: 'tmpl_name', label: '模板名称', width: 150},
        {prop: 'apply_user_name', label: '申请人', width: 120},
        {prop: 'apply_user_title', label: '会内职务', width: 160},
        {prop: 'create_time', sortable: true, label: '提交时间', width: 180},
        {prop: 'approval_user_names', label: '审批人', minWidth: 100},
        {prop: 'status_text', label: '审批状态', width: 100},
        {prop: 'finished_time', sortable: true, label: '完成时间', width: 180,
        visible: (r, q) => q.tab === 'checked'},
        /*
        * 已通过：查看（进去后在底部显示审批记录按钮）

        已拒绝：查看（进去后底部显示通过，可以重新通过）

        已撤销：没有操作
                * */
        { type: 'operation', operations: [
            {command: 'approval', text: '审批', visible: (r, q) => q.tab === 'handling'},
            {command: 'view', text: '查看', visible: (r, q) => ['checked', 'rejected', 'records'].includes(q.tab)},
            // {command: 'approval_records', text: '审批记录', visible: (r, q) => q.tab === 'records'}
          ], visible: (r, q) => q.tab !== 'cancel',
          label: '操作', width: this.$route.name === 'ApprovalTmplRecord' ? 140 : 80
        }
      ],
      // handling：待处理、checked：已通过、rejected：已拒绝、cancel：已撤销、records：记录
      tabArr: [
        {
          name: 'handling',
          label: '待处理',
          badge: 1
        },
        {
          name: 'checked',
          label: '已通过'
        },
        {
          name: 'rejected',
          label: '已拒绝'
        },
        {
          name: 'cancel',
          label: '已撤销'
        }
      ]
    }
  },
  created() {
    if (this.$route.name === 'ApprovalTmplRecord') {
      this.filterForm.tab = 'records'
      this.filterForm.tmpl_id = this.$route.params.id
      this.tabArr = []
    }
  },
  methods: {
    getList(data) {
      return getRecordList({
        ...this.filterForm,
        ...data,
        is_desc: data.is_desc === -1 ? 1 : data.is_desc
      })
    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.refresh()
    },
    refresh() {
      this.$refs.recordLayout.getList();
    },
    handleOperation(e) {
      switch(e.command) {
        case 'approval': {
          // 审批
          this.$router.push({
            name: 'ApprovalDetail',
            params: {id: e.row.id}
          })
          break
        }
        case 'view': {
          this.$router.push({
            name: 'ApprovalRecordDetail',
            params: {id: e.row.id}
          })
          break
        }
        case 'record': {
          break
        }
        case 'approval_records': {
          // TODO: 申请记录-审批记录
          // this.$router.push({
          //   name: 'ApprovalTmplRecordApprovalRecord',
          //   params: {
          //     id: this.$route.params.id,
          //     name: e.row.apply_user_name
          //   }
          // })
          break;
        }
        default:
      }
    },
  },
}
</script>

<style scoped>

</style>
